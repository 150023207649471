import classNames from 'classnames';
import React from 'react'
import styles from './RemindProductIntoCart.module.scss'
import { CartItem } from '~/services/cart';
import CartIcon from "~/assets/svgs/bag.svg";
import Button from '../Button';
import { useRouter } from 'next/router';

type ProductIntoCartProps = {
    cart?: CartItem
}

const ProductIntoCart = ({ cart }: ProductIntoCartProps) => {
    const router = useRouter();

    return (
        <div className={classNames("relative flex")}>
            <div className={classNames(styles.remindImageContainer)}>
                <img
                    width={"100%"}
                    src={cart?.product?.image?.url}
                    alt={cart?.product?.name}
                    className={classNames(styles.remindImage)}
                />
                <div className={classNames(styles.remindCartIcon)}>
                    <CartIcon
                        width={24}
                        height={24}
                        alt="bag"
                        className={classNames(styles.cartIcon)}
                    />
                </div>
            </div>
            <div className={classNames(styles.remindContent)}>
                <div className={classNames(styles.remindTitle)}>
                    Remember this? It's something special!
                </div>
                <small className={"text-red-500"}>Get it before it's gone</small>

                <Button
                    //   disabled={!cartSelector.items?.length}
                    onClick={() => router.push("/checkout")}
                >
                    Proceed to checkout
                </Button>
            </div>
        </div >
    )
}

export default ProductIntoCart