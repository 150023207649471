import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import { CartAtom } from "~/services/cart";
import Icon from "../Icon";
import ProductIntoCart from "./ProductIntoCart";
import { useRouter } from "next/router";
import { PATH } from "~/constants/enum";
type Props = {};
const toastId = "product-into-cart";

const RemindProductIntoCart = (props: Props) => {
  const router = useRouter();

  const { items, cartId } = useRecoilValue(CartAtom.cartSelector);
  const firstItem = items?.[0];
  let initShowRemind = useRef(false);

  useEffect(() => {
    if (!!initShowRemind.current) return;
    if (!items?.length) return;

    const nowMilisecond = new Date().valueOf();
    const lastVisitTime = localStorage.getItem("last_visit_time");
    const lastVisitCartId = localStorage.getItem("last_visit_cartId");
    if (lastVisitTime && lastVisitCartId === cartId) {
      const lastVisitMilisecond = parseInt(lastVisitTime);
      const diff = nowMilisecond - lastVisitMilisecond;
      // last visit 4 tiếng
      // const FOUR_HOURS_IN_MS = 4 * 60 * 60 * 1000;
      if (
        diff > parseInt((process.env.NEXT_PUBLIC_REMIND_TIME || "0") as string)
      ) {
        toast(<ProductIntoCart cart={firstItem} />, {
          onOpen() {
            initShowRemind.current = true;
          },
          toastId: toastId,
          autoClose: false,
          style: {
            display: "block",
            padding: 0,
            borderRadius: 12,
          },
          bodyStyle: {
            padding: 0,
            paddingRight: 8,
          },
          progressClassName: "primary",
          className: "md:w-[400px] md:right-2 !overflow-hidden",
          closeButton: ({ closeToast }: any) => {
            return (
              <Icon
                onClick={closeToast}
                name="close"
                size={32}
                className="text-black hover:text-gray-300 transition-all ms-[0.5px] cursor-pointer absolute top-0 right-0"
              />
            );
          },
        });
        // localStorage.removeItem("last_visit_cartId");
      }
    }
    window.addEventListener("beforeunload", () => {
      const nowMilisecond = new Date().valueOf();
      localStorage.setItem("last_visit_cartId", cartId);
      localStorage.setItem("last_visit_time", nowMilisecond.toString());
    });
  }, [cartId, items]);

  useEffect(() => {
    if (
      router.pathname === PATH.Checkout ||
      router.pathname === PATH.Carts ||
      router.pathname === PATH.ThankYou
    ) {
      return toast.dismiss(toastId);
    }
  }, [router.pathname, toastId]);

  return null;
};

export default RemindProductIntoCart;
