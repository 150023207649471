export enum LocalStorageKey {
  DISCOUNT_CODE = "DISCOUNT_CODE",
}

export const getLocalStorage = (k: LocalStorageKey) => {
  const items = localStorage.getItem(k);
  if (!items) return "";
  try {
    return JSON.parse(items || "");
  } catch (error) {
    console.error("Error getting data from local storage", error);
    return "";
  }
};

export const setLocalStorage = (k: LocalStorageKey, v: any) => {
  try {
    localStorage.setItem(k, JSON.stringify(v));
  } catch (error) {
    console.error("Error setting data to local storage", error);
  }
};

export const removeLocalStorage = (k: LocalStorageKey) => {
  try {
    localStorage.removeItem(k);
  } catch (error) {
    console.error("Error remove item to local storage", error);
  }
};
